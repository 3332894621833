import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const ThankYou = () => {
	return (
		<Flex
			justifyContent={'space-around'}
			flexDirection="column"
			gap="10px"
			mx="auto"
			alignItems={'center'}
			mt="10rem"
		>
			<Text fontSize="5xl">Thank You</Text>
			<Text>We are processing your registration form</Text>
		</Flex>
	)
}

export default ThankYou
