import React, { useState } from 'react'
import { ErrorMessage, PasswordInput } from '../components/InputFields'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { putResetPassword } from '../common/methods'
import { isMobile } from '../common/utils'
import LoginContainer from '../components/LoginContainer'
import { LoginInputGroup, SubmitButtonGroup } from '../components/InputFields'

const ChangePassword = () => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [renderErrorMessage, setRenderErrorMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const navigate = useNavigate()
	const toast = useToast()
	document.title = 'Change Password'

	const queryParameters = new URLSearchParams(window.location.search)
	const secret = queryParameters.get('s')

	const onSubmit = () => {
		if (confirmPassword === password) {
			setIsSubmitting(true)
			setRenderErrorMessage(false)
			const email = document.getElementById('email-input').value
			const data = { email, secret, new_password: password }
			putResetPassword({ data })
				.then(() => {
					navigate('/')
					toast({
						title: 'Password Changed!',
						description: "We've changed your password for you.",
						status: 'success',
						duration: 3000,
						isClosable: true,
						position: 'top',
						containerStyle: {
							width: isMobile ? '300px' : '500px',
							marginTop: isMobile ? '20px' : '50px'
						}
					})
				})
				.catch((res) => {
					const responseMessage = res.response.data.message
					setIsSubmitting(false)
					setRenderErrorMessage(true)
					setErrorMessage(responseMessage)
				})
				.finally(() => {
					setIsSubmitting(false)
				})
		} else {
			setRenderErrorMessage(true)
			setErrorMessage('Passwords dont match')
		}
	}

	return (
		<LoginContainer header="Change Password">
			{renderErrorMessage && <ErrorMessage message={errorMessage} />}
			<LoginInputGroup placeholder="Email" id="email-input" title="Email" />
			<PasswordInput
				password={password}
				setPassword={setPassword}
				placeholder="New Password"
				title="New Password"
			/>
			<PasswordInput
				password={confirmPassword}
				setPassword={setConfirmPassword}
				placeholder={'Confirm New Password'}
				title="Confirm Password"
			/>

			<SubmitButtonGroup
				onSubmit={onSubmit}
				isSubmitting={isSubmitting}
				isErrored={renderErrorMessage}
				errorMessage={errorMessage}
			/>
		</LoginContainer>
	)
}

export default ChangePassword
