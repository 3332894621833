import {
	AppText,
	ErrorMessage,
	SubmitButtonGroup,
	containerFontSize,
	smallFontSize,
	textFontSize
} from '../components/InputFields'
import {
	Flex,
	FormControl,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
	Textarea,
	useColorModeValue,
	useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { bankAccount, personalInfo } from '../data/formFieldTitles'
import { getRegisteredInfo, postRegisteredInfo } from '../common/methods'

import LoginContainer from '../components/LoginContainer'
import { isMobile } from '../common/utils'
import { useNavigate } from 'react-router-dom'

const Register = () => {
	const [formState, setFormState] = useState({
		full_name: null,
		email: null,
		company_name: null,
		address: null,
		postcode: null,
		telephone: null,
		date_of_birth: null,
		national_insurance_number: null,
		bank_account_name: null,
		bank_sort_code: null,
		bank_account_number: null,
		nationality: null,
		email_opt_in: null,
		gender: null,
		only_job: null,
		student_loan: null
	})
	const [errorState, setErrorState] = useState({
		full_name: false,
		address: false,
		postcode: false,
		telephone: false,
		date_of_birth: false,
		national_insurance_number: false,
		bank_account_name: false,
		bank_sort_code: false,
		bank_account_number: false,
		nationality: false,
		email_opt_in: false,
		gender: false,
		only_job: false,
		student_loan: false
	})
	const handleChange = (e) => {
		setFormState({
			...formState,
			[e.target.id]: e.target.value || null
		})
		if (e.target.value) setErrorState({ ...errorState, [e.target.id]: false })
	}
	const queryParameters = new URLSearchParams(window.location.search)
	const url_token = queryParameters.get('t')
	const navigate = useNavigate()
	const toast = useToast()
	const backgroundColor = useColorModeValue(
		'light.selectedBgColor',
		'dark.selectedBgColor'
	)

	useEffect(() => {
		document.title = 'Registration'
		const getInfo = async () => {
			getRegisteredInfo({ id: url_token })
				.then((res) => {
					const { email, company_name } = res
					setFormState({ ...formState, email, company_name })
					if (res.access_token)
						localStorage.setItem('user-token', res.access_token)
				})
				.catch(() => {
					localStorage.clear()
					navigate('/error404')
				})
		}
		getInfo()
	}, [toast, url_token])

	const onSubmit = () => {
		let empties = {}
		const token = localStorage.getItem('user-token')
		Object.entries(formState).forEach(([key, value]) => {
			if (value === null && !['email', 'company_name'].includes(key)) {
				empties = { ...empties, [key]: true }
			}
		})
		if (Object.values(empties).length !== 0) {
			setErrorState(empties)
		} else {
			postRegisteredInfo({
				data: formState,
				token
			})
				.then(() => {
					toast({
						title: 'Registered!',
						description: 'Thank you for registering with Pubmetrix',
						status: 'success',
						duration: 3000,
						isClosable: true,
						position: 'top',
						containerStyle: {
							width: isMobile ? '300px' : '500px',
							marginTop: isMobile ? '20px' : '50px'
						}
					})
					localStorage.clear()
					navigate('/staff/register/thanks')
				})
				.catch(() => {
					toast({
						title: 'Error',
						description: 'Error sending your data your request.',
						status: 'error',
						duration: 3000,
						isClosable: true,
						position: 'top',
						containerStyle: {
							width: isMobile ? '300px' : '500px',
							marginTop: isMobile ? '20px' : '50px'
						}
					})
					localStorage.clear()
					navigate('/error404')
				})
		}
	}

	const renderSwitch = (key, placeholder) => {
		if (['email', 'company_name'].includes(key)) {
			return (
				<Text fontSize={textFontSize} pl={4}>
					{formState[key]}
				</Text>
			)
		} else if (key === 'address') {
			return (
				<Textarea
					onChange={handleChange}
					value={formState[key]}
					id={key}
					h="50px"
					placeholder={personalInfo[key]}
					_placeholder={{
						color: 'dark.primaryBg',
						opacity: 0.5,
						fontSize: textFontSize
					}}
					color="dark.primaryBg"
					errorBorderColor="red.400"
					borderRadius="8px"
					fontSize={textFontSize}
					bg={backgroundColor}
				/>
			)
		} else {
			return (
				<Input
					onChange={handleChange}
					value={formState[key]}
					id={key}
					h={['35px', '40px']}
					placeholder={placeholder}
					_placeholder={{
						color: 'dark.primaryBg',
						opacity: 0.5,
						fontSize: textFontSize
					}}
					color="dark.primaryBg"
					errorBorderColor="red.400"
					borderRadius="8px"
					fontSize={textFontSize}
					bg={backgroundColor}
				/>
			)
		}
	}

	const ClosingStatement = () => {
		return (
			<Text textAlign={'center'} fontSize={smallFontSize}>
				By submitting this form I confirm that all the details are correct and I
				am legally entitled to work in the U.K.
			</Text>
		)
	}

	return (
		<>
			<LoginContainer header="Register">
				{Object.keys(personalInfo).map((id) => (
					<FormControl isInvalid={errorState[id]} key={id} mb=".5rem">
						<AppText value={personalInfo[id]} />
						{renderSwitch(id, personalInfo[id])}
						{errorState[id] ? (
							<Flex position={'absolute'}>
								<ErrorMessage
									message="Field cannot be empty"
									isForInvalidInput={true}
								/>
							</Flex>
						) : null}
					</FormControl>
				))}

				<Text
					fontSize={containerFontSize}
					borderBottom="1px solid lightgray"
					pt="5px"
					fontWeight="bold"
					w={'100%'}
				>
					Bank Account
				</Text>
				{Object.keys(bankAccount).map((id) => (
					<FormControl isInvalid={errorState[id]} key={id} mb=".5rem">
						<Text fontSize={textFontSize}>{bankAccount[id]}</Text>
						{renderSwitch(id, bankAccount[id])}
						{errorState[id] ? (
							<Flex position={'absolute'}>
								<ErrorMessage
									message="Field cannot be empty"
									isForInvalidInput={true}
								/>
							</Flex>
						) : null}
					</FormControl>
				))}
				<Text
					fontSize={containerFontSize}
					borderBottom="1px solid lightgray"
					pt="5px"
					fontWeight="bold"
					w={'100%'}
				>
					HMRC Required
				</Text>
				<FormControl isInvalid={errorState['nationality']} mb=".5rem">
					<Text fontSize={textFontSize}>Nationality</Text>
					<Input
						onChange={handleChange}
						value={formState['nationality']}
						id={'nationality'}
						h={['35px', '40px']}
						placeholder={'Nationality'}
						_placeholder={{
							color: 'dark.primaryBg',
							opacity: 0.5,
							fontSize: textFontSize
						}}
						color="dark.primaryBg"
						errorBorderColor="red.400"
						borderRadius="8px"
						fontSize={textFontSize}
						bg={backgroundColor}
					/>
					{errorState['nationality'] ? (
						<Flex position={'absolute'}>
							<ErrorMessage
								message="Field cannot be empty"
								isForInvalidInput={true}
							/>
						</Flex>
					) : null}
				</FormControl>
				<FormControl
					isInvalid={errorState['national_insurance_number']}
					mb=".5rem"
				>
					<Text fontSize={textFontSize}>National Insurance No.</Text>
					<Input
						onChange={handleChange}
						value={formState['national_insurance_number']}
						id={'national_insurance_number'}
						h={['35px', '40px']}
						placeholder={'National Insurance'}
						_placeholder={{
							color: 'dark.primaryBg',
							opacity: 0.5,
							fontSize: textFontSize
						}}
						color="dark.primaryBg"
						errorBorderColor="red.400"
						borderRadius="8px"
						fontSize={textFontSize}
						bg={backgroundColor}
					/>
					{errorState['national_insurance_number'] ? (
						<Flex position={'absolute'}>
							<ErrorMessage
								message="Field cannot be empty"
								isForInvalidInput={true}
							/>
						</Flex>
					) : null}
				</FormControl>
				<FormControl>
					<Text fontSize={textFontSize}>What is your gender?</Text>
					<RadioGroup width={'100%'}>
						<Stack direction="row">
							<Radio
								isInvalid={errorState['gender']}
								width={'auto'}
								value="Male"
								onChange={() => {
									setFormState({ ...formState, ['gender']: 'Male' })
									setErrorState({ ...errorState, ['gender']: false })
								}}
							>
								<Text fontSize={smallFontSize}>Male</Text>
							</Radio>
							<Radio
								isInvalid={errorState['gender']}
								width={'auto'}
								value="Female"
								onChange={() => {
									setFormState({ ...formState, ['gender']: 'Female' })
									setErrorState({ ...errorState, ['gender']: false })
								}}
							>
								<Text fontSize={smallFontSize}>Female</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<Text fontSize={textFontSize}>Do you have a student loan?</Text>
					<RadioGroup width={'100%'}>
						<Stack direction="row">
							<Radio
								isInvalid={errorState['student_loan']}
								width={'auto'}
								value="Yes"
								onChange={() => {
									setFormState({ ...formState, ['student_loan']: true })
									setErrorState({ ...errorState, ['student_loan']: false })
								}}
							>
								<Text fontSize={smallFontSize}>Yes</Text>
							</Radio>
							<Radio
								isInvalid={errorState['student_loan']}
								width={'auto'}
								value="No"
								onChange={() => {
									setFormState({ ...formState, ['student_loan']: false })
									setErrorState({ ...errorState, ['student_loan']: false })
								}}
							>
								<Text fontSize={smallFontSize}>No</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<Text fontSize={textFontSize}>Is this your only job?</Text>
					<RadioGroup width={'100%'}>
						<Stack direction="row">
							<Radio
								isInvalid={errorState['only_job']}
								width={'auto'}
								value="Yes"
								onChange={() => {
									setFormState({ ...formState, ['only_job']: true })
									setErrorState({ ...errorState, ['only_job']: false })
								}}
							>
								<Text fontSize={smallFontSize}>Yes</Text>
							</Radio>
							<Radio
								isInvalid={errorState['only_job']}
								width={'auto'}
								value="No"
								onChange={() => {
									setFormState({ ...formState, ['only_job']: false })
									setErrorState({ ...errorState, ['only_job']: false })
								}}
							>
								<Text fontSize={smallFontSize}>No</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<Text fontSize={textFontSize}>Opt in to email marketing?</Text>
					<RadioGroup width={'100%'}>
						<Stack direction="row">
							<Radio
								isInvalid={errorState['email_opt_in']}
								width={'auto'}
								value="Yes"
								onChange={() => {
									setFormState({ ...formState, ['email_opt_in']: true })
									setErrorState({ ...errorState, ['email_opt_in']: false })
								}}
							>
								<Text fontSize={smallFontSize}>Yes</Text>
							</Radio>
							<Radio
								isInvalid={errorState['email_opt_in']}
								width={'auto'}
								value="No"
								onChange={() => {
									setFormState({ ...formState, ['email_opt_in']: false })
									setErrorState({ ...errorState, ['email_opt_in']: false })
								}}
							>
								<Text fontSize={smallFontSize}>No</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				</FormControl>
				<ClosingStatement />
				<SubmitButtonGroup onSubmit={onSubmit} />
			</LoginContainer>
		</>
	)
}

export default Register
