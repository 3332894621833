import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

import {
	AppButton,
	AppText,
	containerFontSize,
	smallFontSize,
	textFontSize
} from '../../components/InputFields'
import {
	Flex,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	NumberInput,
	NumberInputField,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { AddMetricFields } from '../../data/addMetricsModalTitles'
import DatePicker from 'react-datepicker'
import { MetricsDataContext } from '../../providers/MetricsProvider'
import { NumberStepper } from '../../components/InputFields'
import { isMobile } from '../../common/utils'
import { postMetrics } from '../../common/methods'
import { useContext } from 'react'
import useGetMetricsTemplate from '../../hooks/useGetMetricsTemplate'

const CalendarContainer = ({ startDate, setStartDate }) => {
	return (
		<FormControl mt={3}>
			<AppText value="Week Ending:" />
			<DatePicker
				closeOnScroll={false}
				selected={startDate}
				onChange={(date) => setStartDate(date)}
				calendarStartDay={1}
				dateFormat="dd/MM/yyyy"
				popperPlacement="top"
				maxDate={new Date()}
			/>
		</FormControl>
	)
}

function MetricInput({ id, label, value: newValue }) {
	const format = (val) => {
		return value === '' ? '' : `£ ${val}`
	}

	const [value, setValue] = React.useState('')

	const onChange = (value) => {
		setValue(value)
	}

	const onBlur = (e) => {
		const number = e.target.value
		if (number === '') {
			setValue('')
		} else {
			onChange(parseFloat(number) % 0.25 === 0 ? number : parseInt(number))
		}
	}

	useEffect(() => {
		setValue(newValue)
	}, [newValue])

	return (
		<Flex flexDirection="column" mb="5px">
			<AppText value={label} />
			<NumberInput
				value={format(value)}
				onChange={onChange}
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				borderRadius="10px"
				min={0}
				max={20000}
				w="90%"
				fontSize={textFontSize}
				mb="5px"
			>
				<NumberInputField
					h={['35px', '50px']}
					color={useColorModeValue('black', 'dark.primaryBg')}
					_placeholder={{ fontSize: textFontSize, color: 'gray' }}
					placeholder={'£ 0.00'}
					fontSize={textFontSize}
					onBlur={onBlur}
					paddingLeft="10px"
					id={id}
				/>
				<NumberStepper />
			</NumberInput>
		</Flex>
	)
}

function createBodyForAPI({ categories, fields }) {
	return fields.map(({ inputField, categoryName, id }) => {
		const categoryId = categories.find(
			(category) => category.name === categoryName
		).id

		const rawUserInput = document.getElementById(`${id}-input`).value

		let userInput = rawUserInput.slice(2)
		userInput = userInput ? userInput : 0
		return { id: categoryId, value: userInput }
	})
}

function getLastSunday() {
	var now = new Date()
	var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
	return new Date(today.setDate(today.getDate() - today.getDay()))
}

function getNextSunday(date) {
	const dateCopy = new Date(date.getTime())

	const nextSunday = new Date(
		dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay()) % 7 || 0))
	)

	return nextSunday.toLocaleDateString('en-gb')
}

const AddMetricModal = ({ show, onClose, title }) => {
	const toast = useToast()
	const metricsCategories = useGetMetricsTemplate()
	const accessToken = localStorage.getItem('user-token')
	const [startDate, setStartDate] = useState(getLastSunday() ?? '')

	const [isSubmitting, setIsSubmitting] = useState(false)

	const {
		salesDryHistory,
		salesWetHistory,
		salesDryCostHistory,
		salesWetCostHistory,
		utilitiesHistory,
		payrollHistory
	} = useContext(MetricsDataContext)

	// Manage formstate of the user inputs
	const [formState, setFormState] = useState({
		sales_wet: '',
		sales_dry: '',
		cost_wet: '',
		cost_dry: '',
		utilities: '',
		payroll: ''
	})

	// set formstate with updated values when the date changes
	useEffect(() => {
		const nextSunday = getNextSunday(startDate ?? new Date()) // DD/MM/YYYY
		const sales_wet_value =
			salesWetHistory.find((record) => record.week_ending_date === nextSunday)
				?.value || ''
		const sales_dry_value =
			salesDryHistory.find((record) => record.week_ending_date === nextSunday)
				?.value || ''
		const cost_dry_value =
			salesDryCostHistory.find(
				(record) => record.week_ending_date === nextSunday
			)?.value || ''
		const cost_wet_value =
			salesWetCostHistory.find(
				(record) => record.week_ending_date === nextSunday
			)?.value || ''
		const payroll_value =
			payrollHistory.find((record) => record.week_ending_date === nextSunday)
				?.value || ''
		const utilities_value =
			utilitiesHistory.find((record) => record.week_ending_date === nextSunday)
				?.value || ''

		setFormState({
			sales_wet: sales_wet_value,
			sales_dry: sales_dry_value,
			cost_wet: cost_wet_value,
			cost_dry: cost_dry_value,
			utilities: utilities_value,
			payroll: payroll_value
		})
	}, [
		startDate,
		payrollHistory,
		salesWetCostHistory,
		salesDryHistory,
		salesDryCostHistory,
		salesWetHistory,
		utilitiesHistory
	])

	const onConfirm = () => {
		setIsSubmitting(true)
		const values = createBodyForAPI({
			categories: metricsCategories,
			fields: AddMetricFields,
			formState
		})

		const data = { week_ending_date: startDate, values }

		postMetrics({ data, token: accessToken })
			.then(() => {
				setIsSubmitting(false)
				toast({
					title: 'Metrix Added!',
					description: 'They will be shown on the screen below.',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top',
					containerStyle: {
						width: isMobile ? '300px' : '500px',
						marginTop: isMobile ? '20px' : '50px'
					}
				})
			})
			.finally(() => {
				onClose()
			})
	}

	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader px="20px" py="15px">
					<Text fontSize={containerFontSize}>
						{title}
						<Text fontSize={smallFontSize} display="inline-block" pl="15px">
							(excl. VAT)
						</Text>
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody py={0}>
					{Object.keys(formState).map((id) => (
						<MetricInput
							key={id}
							id={`${id}-input`}
							setFormState={setFormState}
							formState={formState}
							value={formState[id]}
							label={
								AddMetricFields.find((field) => field.id === id).inputField
							}
						/>
					))}

					<CalendarContainer
						startDate={startDate}
						setStartDate={setStartDate}
					/>
				</ModalBody>
				<ModalFooter justifyContent="center" gap="20px">
					<AppButton
						value="Add Metrix"
						onClick={onConfirm}
						isLoading={isSubmitting}
						my="10px"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default AddMetricModal
