import { GridItem, Text } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

export function ScrollDown() {
	return (
		<GridItem colSpan={2}>
			<Text textAlign={'center'}>
				<ChevronDownIcon />
				Scroll Down
				<ChevronDownIcon />
			</Text>
		</GridItem>
	)
}
