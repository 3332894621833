import { Box, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import { headerFontSize, smallFontSize, textFontSize } from './InputFields'

import Graph from './LineGraph'

export const Widget = ({ title, sub, value, sign, isLoading }) => {
	return (
		<Box
			textAlign="center"
			bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
			color={useColorModeValue('light.textColor', 'dark.textColor')}
			borderRadius="5px"
			p="1rem"
		>
			<Text fontSize={textFontSize} fontWeight="bold">
				{title}
			</Text>
			<Text fontSize={smallFontSize} fontWeight="bold">
				{sub}
			</Text>
			<Text fontSize={headerFontSize} fontWeight="bold">
				{isLoading ? <Spinner /> :
					<>
						<Text
							fontSize="md"
							style={{ display: 'inline-block', paddingRight: '5px' }}
						>
							{sign}
						</Text>
						<Text as="span">{value}</Text>
					</>
				}
			</Text>
		</Box>
	)
}

export const LineGraph = ({ title, sub, isDark, ...rest }) => (
	<Box
		textAlign="center"
		h={['220px', '280px', '330px']}
		bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
		color={useColorModeValue('light.textColor', 'dark.textColor')}
		borderRadius="5px"
		pt="5px"
	>
		<Text fontSize={textFontSize} fontWeight="bold">
			{title}
		</Text>
		<Text fontSize={smallFontSize} fontWeight="bold">
			{sub}
		</Text>
		<Graph {...rest} isDark={isDark} />
	</Box>
)
