export const AddMetricFields = [
	{ inputField: 'Wet Sales', categoryName: 'Sales Wet', id: 'sales_wet' },
	{ inputField: 'Dry Sales', categoryName: 'Sales Dry', id: 'sales_dry' },
	{
		inputField: 'Cost Of Wet Sales',
		categoryName: 'Cost of Goods Wet',
		id: 'cost_wet'
	},
	{
		inputField: 'Cost Of Dry Sales',
		categoryName: 'Cost of Goods Dry',
		id: 'cost_dry'
	},
	{ inputField: 'Payroll', categoryName: 'Staff Costs Staff', id: 'payroll' },
	{
		inputField: 'Utilities',
		categoryName: 'Operating Expenses Utilities',
		id: 'utilities'
	}
]
