export const isEmpty = (string) => string.length === 0

export const isHome = (string) => string === '/'

export const isMobile = window.innerWidth < 500

export const Capitalise = (string) => {
	const restOfPhrase = string.slice(1)
	const newPhrase = string.charAt(0).toUpperCase() + restOfPhrase
	return newPhrase
}

export const ellipsesTextProps = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis'
}
