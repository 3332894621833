import {
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue
} from '@chakra-ui/react'
import React, { useState } from 'react'

import { AppButton } from '../../components/InputFields'
import { Select } from '@chakra-ui/react'
import { containerFontSize } from '../../components/InputFields'
import { deleteStaffMember } from '../../common/methods'

const DeleteModal = ({ staff, show, onClose, title, sub }) => {
	const accessToken = localStorage.getItem('user-token')
	const [id, setId] = useState('')
	const [isSelected, setIsSelected] = useState(false)

	const onConfirm = () => {
		deleteStaffMember({ id, token: accessToken }).then((res) => onClose())
	}

	const onSelect = (e) => {
		setIsSelected(true)
		setId(e.target.value)
	}

	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader>
					<Text fontSize={containerFontSize}>{title}</Text>
					<Text as="sub">{sub}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl>
						<Text fontWeight={'bold'}>Staff member:</Text>
						<Select onChange={onSelect} id="staffSelect">
							<option hidden>Choose staff member</option>
							{staff.map((member) => (
								<option value={member.id} key={member.id}>
									{member.first_name}
								</option>
							))}
						</Select>
					</FormControl>
				</ModalBody>

				<ModalFooter justifyContent="center" gap="20px">
					<AppButton
						onClick={onConfirm}
						disabled={!isSelected}
						value="Confirm"
					/>
					<AppButton onClick={onClose} value="Close" />
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default DeleteModal
