import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const config = {
	initialColorMode: 'dark'
}

const theme = extendTheme({
	config,
	styles: {
		global: (props) => ({
			body: {
				fontFamily: 'Montserrat, sans-serif',
				bg: mode('dark.primaryBg', 'dark.primaryBg')(props)
			}
		})
	},

	colors: {
		dark: {
			primaryBg: 'hsl(211, 12%, 20%)',
			secondaryBg: 'hsl(211, 12%, 40%)',
			navbar: '#4A7B6F',
			selectedBgColor: '#EEEEE7',
			textColor: '#EEEEE7'
		},
		light: {
			primaryBg: 'hsl(211, 12%, 80%)',
			secondaryBg: 'hsl(211, 12%, 70%)',
			navbar: '#4A7B6F',
			selectedBgColor: '#EEEEE7',
			textColor: '#242E35'
		}
	}
})

export default theme
