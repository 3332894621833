import { ErrorMessage, PasswordInput } from '../../components/InputFields'
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'

import { AppButton } from '../../components/InputFields'
import { isMobile } from '../../common/utils'
import { putChangePassword } from '../../common/methods'

const ChangePasswordModal = ({ show, onClose, token }) => {
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [renderErrorMessage, setRenderErrorMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const toast = useToast()

	const onSubmit = () => {
		if (confirmPassword === newPassword) {
			setIsSubmitting(true)
			setRenderErrorMessage(false)
			const changePasswordData = {
				current_password: currentPassword.trim(),
				new_password: newPassword.trim()
			}
			putChangePassword({ data: changePasswordData, token })
				.then(() => {
					onClose()
					toast({
						title: 'Password Changed!',
						status: 'success',
						duration: 3000,
						isClosable: true,
						position: 'top',
						containerStyle: {
							width: isMobile ? '300px' : '500px',
							marginTop: isMobile ? '20px' : '50px'
						}
					})
				})
				.catch((res) => {
					setIsSubmitting(false)
					setRenderErrorMessage(true)
					setErrorMessage(res.response.data.message)
				})
		} else {
			setRenderErrorMessage(true)
			setErrorMessage('Passwords dont match')
		}
	}

	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader>
					<Text>Change Password</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody p={4}>
					<Text>Current Password:</Text>
					<PasswordInput
						password={currentPassword}
						setPassword={setCurrentPassword}
						placeholder="Current Password"
					/>
					<Text mt="10px">New Password:</Text>
					<PasswordInput
						password={newPassword}
						setPassword={setNewPassword}
						placeholder="New Password"
					/>
					<Text mt="10px">Confirm Password:</Text>
					<PasswordInput
						password={confirmPassword}
						setPassword={setConfirmPassword}
						placeholder="Confirm Password"
					/>
					{renderErrorMessage && <ErrorMessage message={errorMessage} />}
				</ModalBody>
				<ModalFooter justifyContent="center" gap="20px">
					<AppButton
						onClick={onSubmit}
						isLoading={isSubmitting}
						value="Submit"
					/>
					<AppButton onClick={onClose} value="Close" />
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ChangePasswordModal
