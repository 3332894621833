import {
	AppButton,
	ErrorMessage,
	InputField,
	UserNumberInputField,
	containerFontSize
} from '../../components/InputFields'
import {
	Box,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	useColorModeValue
} from '@chakra-ui/react'
import React, { useState } from 'react'

import { putStaffMember } from '../../common/methods'

const EditModal = ({ staff, show, onClose, title }) => {
	const accessToken = localStorage.getItem('user-token')
	const [valueSelected, setValueSelected] = useState(false)
	const [firstname, setFirstname] = useState('')
	const [surname, setSurname] = useState('')
	const [email, setEmail] = useState('')
	const [rate, setRate] = useState('')
	const [id, setId] = useState('')
	const [isDataInvalid, setIsDataInvalid] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const onChangeFirstname = (e) => setFirstname(e.target.value)
	const onChangeSurname = (e) => setSurname(e.target.value)
	const onChangeEmail = (e) => setEmail(e.target.value)

	const onConfirm = () => {
		let hourly_rate = document.getElementById('rate-input').value.slice(2)
		hourly_rate = hourly_rate ? hourly_rate : 0

		const data = {
			first_name: firstname.trim(),
			last_name: surname.trim(),
			email: email.toLowerCase(),
			hourly_rate,
			start_date: null,
			end_date: null
		}
		putStaffMember({ id, data, token: accessToken })
			.then(() => onClose())
			.catch((res) => {
				setIsDataInvalid(true)
				setErrorMessage(res.response.data.message)
			})
	}

	const onSelect = (e) => {
		const { first_name, last_name, email, id, hourly_rate } = staff.find(
			({ id }) => id === e.target.value
		)
		setFirstname(first_name)
		setSurname(last_name)
		setEmail(email)
		setId(id)
		setRate(hourly_rate)
		setValueSelected(true)
	}

	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader>
					<Text fontSize={containerFontSize}>{title}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl>
						<Text fontWeight={'bold'}>Staff member:</Text>
						<Select onChange={onSelect} id="staffSelect">
							<option selected hidden>
								Choose staff member
							</option>
							{staff.map((member) => (
								<option value={member.id}>{member.first_name}</option>
							))}
						</Select>
					</FormControl>

					<div
						style={{
							width: '100%',
							borderBottom: '1px solid lightgray',
							margin: '20px 0 20px 0 '
						}}
					/>

					<InputField
						placeholder="First name"
						value={firstname}
						onChange={onChangeFirstname}
						disabled={!valueSelected}
						title="Firstname:"
					/>

					<InputField
						placeholder="Last name"
						value={surname}
						onChange={onChangeSurname}
						disabled={!valueSelected}
						title="Lastname:"
					/>

					<InputField
						placeholder="Email"
						onChange={onChangeEmail}
						value={email}
						disabled={!valueSelected}
						title="Email: "
					/>

					<Box mt="10px">
						<UserNumberInputField
							title="Rate"
							id="rate-input"
							step={0.25}
							disabled={!valueSelected}
							stepper={true}
							value={rate}
							min={0}
							max={50}
						/>
					</Box>
					{isDataInvalid && <ErrorMessage message={errorMessage} />}
				</ModalBody>

				<ModalFooter justifyContent="center" gap="20px">
					<AppButton
						onClick={onConfirm}
						disabled={!valueSelected}
						value="Confirm"
					/>
					<AppButton onClick={onClose} value="Close" />
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default EditModal
