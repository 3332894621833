import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip
} from 'chart.js'
import { Flex, Spinner } from '@chakra-ui/react'

import { Line } from 'react-chartjs-2'
import React from 'react'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

const Graph = ({ datasets, labels, isDark, isPercentage, isLoading }) => {
	const data = {
		labels,
		datasets
	}

	const options = {
		responsive: true,

		plugins: {
			legend: {
				labels: {
					color: isDark ? 'white' : 'black'
				}
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const value = isPercentage
							? context.parsed.y.toFixed(1)
							: context.parsed.y.toLocaleString('en-us')
						const label = context.dataset.label
						const sign = isPercentage ? '%' : ''
						return ` ${label} ${value}` + sign
					}
				}
			}
		},
		scales: {
			y: {
				ticks: {
					color: isDark ? 'white' : 'black',
					font: {
						size: '13px'
					},
					stepSize: 1
				}
			},
			x: {
				ticks: {
					color: isDark ? 'white' : 'black',
					font: {
						size: '13px'
					},
					stepSize: 1
				}
			}
		}
	}

	return isLoading ? <Spinner /> :
		<Flex height={['173px', '210px', '260px']} justifyContent={'space-around'}>
			<Line options={options} data={data} />
		</Flex>
}

export default Graph
