import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons'
import {
	Button,
	Flex,
	Grid,
	GridItem,
	Spinner,
	Text,
	useColorMode
} from '@chakra-ui/react'
import { LineGraph, Widget } from '../components/Widget'
import React, { useContext } from 'react'

import { AccountDataContext } from '../providers/AccountProvider'
import AddMetricModal from './Modals/AddMetricModal'
import { MetricsDataContext } from '../providers/MetricsProvider'
import PageContainer from '../components/PageContainer'
import { ScrollDown } from '../components/scrollDownLabel'
import { isMobile } from '../common/utils'

const Dashboard = () => {
	const { colorMode } = useColorMode()
	const isDark = colorMode === 'dark'
	document.title = 'Dashboard'

	const {
		payrollAvg,
		utilitiesAvg,
		salesDryAvg,
		salesWetAvg,
		salesHistoryDataset,
		costHistoryDataset,
		totalSales,
		totalCost,
		grossProfit,
		netProfit,
		labels,
		isLoading: isLoadingMetrics,
		setShowAddMetricModal,
		showAddMetricModal,
	} = useContext(MetricsDataContext)

	const { isLoading: isLoadingName, accountInfo } = useContext(AccountDataContext)

	return (
		<PageContainer>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				height={isMobile ? '55px' : '70px'}
				px={isMobile ? '20px' : '0'}
			>
				<Text fontSize={['24px', '26px', '30px']} fontWeight="bold">
					{isLoadingName ? <Spinner /> : <Text>Welcome {!isLoadingName ? accountInfo.name.split(' ')[0] : ''}</Text>}
				</Text>
				<Button
					variant="outline"
					colorScheme="green"
					onClick={() => setShowAddMetricModal(true)}
					border="2px solid"
					borderColor={'dark.navbar'}
					p="5px"
					borderRadius="5px"
					width={['2rem', '9rem', '11rem']}
					gap={['5px', '6px', '10px']}
				>
					<AddIcon fontSize={['sm', 'md', 'lg']} color="dark.navbar" />
					{!isMobile && (
						<Text fontSize={['10px', '15px', '19px']}>Add Metrix</Text>
					)}
				</Button>
			</Flex>

			<Grid templateColumns="repeat(2, 1fr)" gap={1}>
				<ScrollDown />
				<GridItem colSpan={2} h={['220px', '280px', '330px']}>
					<LineGraph
						title="Sales History"
						sub="total £ / week"
						labels={labels}
						datasets={salesHistoryDataset}
						isDark={isDark}
						isPercentage={false}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Sales (Wet)"
						sub="avg. £ sales"
						sign="£"
						value={Math.round(salesWetAvg).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Sales (Dry)"
						sub="avg. £ sales"
						sign="£"
						value={Math.round(salesDryAvg).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Payroll"
						sub="avg. in last 4 weeks"
						sign="£"
						value={Math.round(payrollAvg).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Utilities"
						sub="avg. in last 4 weeks"
						sign="£"
						value={Math.round(utilitiesAvg).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem colSpan={2}>
					<Text textAlign={'center'}>
						<ChevronDownIcon />
						Scroll Down
						<ChevronDownIcon />
					</Text>
				</GridItem>
				<GridItem colSpan={2} h={['220px', '280px', '330px']}>
					<LineGraph
						title="Cost History"
						sub="% / Total Sales"
						labels={labels}
						isDark={isDark}
						datasets={costHistoryDataset}
						isPercentage={true}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Total Sales"
						sub="last 4 weeks"
						sign="£"
						value={Math.round(totalSales).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Total Cost"
						sub="last 4 weeks"
						sign="£"
						value={Math.round(totalCost).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Gross Profit"
						sub="last 4 weeks"
						sign="£"
						value={Math.round(grossProfit).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem>
					<Widget
						title="Net Profit"
						sub="last 4 weeks"
						sign="£"
						value={Math.round(netProfit).toLocaleString('en-US')}
						isDark={isDark}
						isLoading={isLoadingMetrics}
					/>
				</GridItem>
				<GridItem colSpan={2}>
					<Text textAlign={'center'}>
						Net Profit is based on metrix provided
					</Text>
				</GridItem>
			</Grid>
			{showAddMetricModal && (
				<AddMetricModal
					show={showAddMetricModal}
					onClose={() => setShowAddMetricModal(false)}
					title="Add Metrix"
					sub="Add the 6 metrix below here:"
				/>
			)}
		</PageContainer>
	)
}

export default Dashboard
