import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import theme from './theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<ChakraProvider theme={theme}>
		<App />
	</ChakraProvider>
)
