import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { ErrorBoundary } from 'react-error-boundary'
import { smallFontSize } from './InputFields'
import { useColorModeValue } from '@chakra-ui/react'

export default function ErrorBoundaryHandler({ children }) {
	function ErrorFallback(props) {
		return (
			<Box
				bg={useColorModeValue('light.primaryBg', 'dark.primaryBg')}
				width="100vw"
				min-height="100vh"
			>
				<Flex
					justifyContent={'space-around'}
					flexDirection="column"
					gap="10px"
					mx="auto"
					alignItems={'center'}
					mt="10rem"
				>
					<Text fontSize="4xl" textAlign="center">
						Oops...
					</Text>
					<Text fontSize="2xl">Something went wrong</Text>
					<Text textAlign="center">{props.error.message}</Text>
					<Text textAlign="center" fontSize={smallFontSize}>
						If this problem persists please contact support
					</Text>
					<Button onClick={props.resetErrorBoundary}>Return</Button>
				</Flex>
			</Box>
		)
	}

	return (
		<ErrorBoundary fallbackRender={(props) => <ErrorFallback {...props} />}>
			{children}
		</ErrorBoundary>
	)
}
