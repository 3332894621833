import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
	const navigate = useNavigate()

	const [isLoggedIn, setIsLoggedIn] = useState(false)

	const checkUserToken = () => {
		const userToken = localStorage.getItem('user-token')
		if (!userToken || userToken === 'undefined') {
			setIsLoggedIn(false)
			localStorage.removeItem('user-token')
			return navigate('/login')
		}
		setIsLoggedIn(true)
	}

	useEffect(() => {
		checkUserToken()
		// eslint-disable-next-line
	}, [isLoggedIn])

	return <>{isLoggedIn && children}</>
}
export default ProtectedRoute
