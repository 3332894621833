import { useEffect, useState } from 'react'
import { getMetricsTemplate } from '../common/methods'

function processCategories({ categories }) {
	return categories
		.map(({ id, is_incoming, ...keepAttrs }) => {
			const { metrics, name: baseName } = keepAttrs
			return metrics.map((metric) => {
				metric.name = `${baseName} ${metric.name}`
				return metric
			})
		})
		.flat()
}

const useGetMetricsTemplate = () => {
	const [metricsCategories, setMetricsCategories] = useState([])
	const accessToken = localStorage.getItem('user-token')
	useEffect(() => {
		getMetricsTemplate({ token: accessToken }).then((res) => {
			const { categories } = res
			const processedCategories = processCategories({ categories })
			setMetricsCategories(processedCategories)
		})
	}, [accessToken])
	return metricsCategories
}

export default useGetMetricsTemplate
