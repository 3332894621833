import { Box, Checkbox, Flex, Link, Text, useToast } from '@chakra-ui/react'
import { LoginInputGroup, SubmitButtonGroup } from '../components/InputFields'
import {
	PasswordInput,
	smallFontSize,
	textFontSize
} from '../components/InputFields'
import React, { useState } from 'react'
import { isEmpty, isMobile } from '../common/utils'

import { ErrorMessage } from '../components/InputFields'
import LoginContainer from '../components/LoginContainer'
import { postSignIn } from '../common/methods'
import { useNavigate } from 'react-router-dom'

const Login = () => {
	const [username, setUsername] = useState('')
	const [usernameInvalid, setUsernameInvalid] = useState(false)
	const [passwordInvalid, setPasswordInvalid] = useState(false)
	const [password, setPassword] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isLoginFailed, setIsLoginFailed] = useState(false)
	const navigate = useNavigate()
	const toast = useToast()
	const isUnderMaintenance =
		process.env.REACT_APP_IS_UNDER_MAINTENANCE === 'true'
	document.title = 'Login'
	const onChangeUsername = (e) => {
		setUsernameInvalid(false)
		setUsername(e.target.value)
	}

	const disabledStyle = isUnderMaintenance ? { pointerEvents: 'none' } : {}

	const onSubmit = () => {
		if (isEmpty(username) || isEmpty(password)) {
			isEmpty(username) && setUsernameInvalid(true)
			isEmpty(password) && setPasswordInvalid(true)
			return
		}
		setPasswordInvalid(false)
		setUsernameInvalid(false)
		setIsSubmitting(true)
		setIsLoginFailed(false)
		const data = { email: username.toLowerCase(), password }
		postSignIn({ data })
			.then((res) => {
				const { access_token } = res
				localStorage.setItem('user-token', access_token)
				navigate('/home')
			})
			.catch(() => {
				setIsLoginFailed(true)
			})
			.finally(() => {
				setIsSubmitting(false)
				setUsername('')
				setPassword('')
			})
	}

	const showToast = () => {
		const id = 'test-toast'
		if (!toast.isActive(id)) {
			toast({
				id,
				title: 'Planned Maintenance',
				description:
					'We are currently going through some planned maintenance, and we will back as soon as possible. Thank you.',
				status: 'info',
				duration: 100000,
				position: 'top',
				containerStyle: {
					width: isMobile ? '300px' : '500px',
					marginTop: isMobile ? '20px' : '50px',
					fontSize: isMobile ? '14px' : '15px',
					alignItems: 'center'
				}
			})
		}
	}

	return (
		<>
			{isUnderMaintenance && showToast()}
			<LoginContainer header="Login">
				{isLoginFailed && (
					<ErrorMessage message={'Incorrect username/password combination'} />
				)}
				<LoginInputGroup
					value={username}
					onChange={onChangeUsername}
					isInvalid={usernameInvalid}
					placeholder="Email"
					title="Username"
				/>

				<PasswordInput
					password={password}
					setPassword={setPassword}
					isInvalid={passwordInvalid}
					setisInvalid={setPasswordInvalid}
					placeholder="Password"
					title="Password"
				/>
				<Flex justifyContent="space-between" w="100%">
					<Checkbox>
						<Text fontSize={smallFontSize} color="dark.selectedBgColor">
							Remember Me
						</Text>
					</Checkbox>
					<Link onClick={() => navigate('/forgot-password')} style={disabledStyle}>
						<Text fontSize={smallFontSize} as="i" color="dark.selectedBgColor">
							Forgot Password?
						</Text>
					</Link>
				</Flex>

				<SubmitButtonGroup
					onSubmit={onSubmit}
					isSubmitting={isSubmitting}
					disabled={isUnderMaintenance}
				/>
				<Box w="100%" borderBottom="1px solid gray" />
				<Text fontSize={textFontSize} color="dark.selectedBgColor">
					Dont have an account? <Link href="/signup" style={disabledStyle}>Sign up!</Link>
				</Text>
			</LoginContainer>
		</>
	)
}

export default Login
