import React, { useState } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { InputField } from '../components/InputFields'
import PageContainer from '../components/PageContainer'
import Container from '../components/Container'
import { UserNumberInputField, AppButton } from '../components/InputFields'
import { postStaffMember } from '../common/methods'
import { ErrorMessage } from '../components/InputFields'
import { isMobile } from '../common/utils'

const RegistrationForm = () => {
	const accessToken = localStorage.getItem('user-token')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isDataInvalid, setIsDataInvalid] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const toast = useToast()
	document.title = 'Staff Registration'

	const onSubmit = () => {
		setIsSubmitting(true)

		let hourly_rate = document
			.getElementById('rate-registration')
			.value.slice(2)

		hourly_rate = hourly_rate ? hourly_rate : '0'

		const data = {
			first_name: document
				.getElementById('firstname-registration')
				.value.trim(),
			last_name: document.getElementById('lastname-registration').value.trim(),
			email: document.getElementById('email-registration').value.toLowerCase(),
			hourly_rate,
			start_date: null,
			end_date: null
		}
		postStaffMember({ data, token: accessToken })
			.then(() => {
				reset()
				toast({
					title: 'Email Sent',
					description: "We've sent the employee their registration form .",
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top',
					containerStyle: {
						width: isMobile ? '300px' : '500px',
						marginTop: isMobile ? '20px' : '50px'
					}
				})
				setIsDataInvalid(false)
			})
			.catch((res) => {
				setIsDataInvalid(true)
				setErrorMessage(res.response.data.message[0])
			})
			.finally(() => setIsSubmitting(false))
	}

	function reset() {
		document.getElementById('firstname-registration').value = ''
		document.getElementById('lastname-registration').value = ''
		document.getElementById('email-registration').value = ''
		document.getElementById('rate-registration').value = ''
	}

	return (
		<PageContainer header="Staff">
			<Container header="Personnel Details">
				<Box w="100%" h="100%" p="4px">
					<InputField title="First Name:" id="firstname-registration" />
					<InputField title="Last Name:" id="lastname-registration" />
					<InputField title="Email:" id="email-registration" />
					<Box mt="10px">
						<UserNumberInputField
							type="money"
							title="Rate:"
							id="rate-registration"
							step={0.25}
							stepper={true}
							min={0}
							max={50}
						/>
					</Box>
				</Box>
				<AppButton
					mt="15px"
					isLoading={isSubmitting}
					onClick={onSubmit}
					value="Register"
				/>
				{isDataInvalid && <ErrorMessage message={errorMessage} />}
			</Container>
		</PageContainer>
	)
}

export default RegistrationForm
