import { Box, Text, useColorModeValue } from '@chakra-ui/react'

import React from 'react'
import { containerFontSize } from './InputFields'

const Container = ({ children, header = null, disabled = false }) => {
	return (
		<Box
			alignItems={'center'}
			opacity={disabled ? 0.5 : 1}
			bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
			color={useColorModeValue('black', 'dark.textColor')}
			borderRadius="5px"
			p=".75rem"
			my=".5rem"
		>
			{header && (
				<Text
					fontSize={containerFontSize}
					borderBottom="1px solid lightgray"
					mb="10px"
					pb="5px"
					fontWeight="bold"
				>
					{header}
				</Text>
			)}
			{children}
		</Box>
	)
}

export default Container
