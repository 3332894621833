import {
	Button,
	Center,
	HStack,
	Input,
	InputGroup,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text,
	useColorModeValue
} from '@chakra-ui/react'
import React, { useState } from 'react'

import { useEffect } from 'react'

export const headerFontSize = ['26px', '29px', '32px']
export const containerFontSize = ['20px', '22px', '25px']
export const textFontSize = ['15px', '17px', '18px']
export const smallFontSize = ['13px', '14px', '15px']

export function AppText({ value, ...textProps }) {
	return (
		<Text
			fontSize={textFontSize}
			color={useColorModeValue('black', 'dark.textColor')}
			{...textProps}
		>
			{value}
		</Text>
	)
}

export function AppButton({ value, ...buttonProps }) {
	return (
		<Center>
			<Button
				height={'40px'}
				color="black"
				fontSize={smallFontSize}
				borderRadius="10px"
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				{...buttonProps}
			>
				{value}
			</Button>
		</Center>
	)
}

export const ErrorMessage = ({ message, isForInvalidInput }) => {
	const position = { textAlign: isForInvalidInput ? 'left' : 'Center' }
	return (
		<Text
			fontSize={isForInvalidInput ? smallFontSize : textFontSize}
			color={useColorModeValue('red.700', 'red.300')}
			fontWeight="bold"
			mt="10px"
			{...position}
		>
			{message}
		</Text>
	)
}

export const InputField = ({ title, ...rest }) => {
	return (
		<HStack spacing={4} mt="10px" justifyContent="space-between">
			{title && <AppText value={title} />}
			<Input
				color={useColorModeValue('black', 'dark.primaryBg')}
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				_placeholder={{
					color: 'dark.textColor',
					opacity: 0.5
				}}
				h={['35px', '50px']}
				w="60%"
				fontSize={textFontSize}
				{...rest}
			/>
		</HStack>
	)
}

export function SubmitButtonGroup({ onSubmit, isSubmitting, disabled }) {
	return (
		<Button
			isDisabled={disabled}
			onClick={onSubmit}
			isLoading={isSubmitting}
			fontSize={textFontSize}
			color="dark.primaryBg"
			borderRadius="10px"
			bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
		>
			Submit
		</Button>
	)
}

export function LoginInputGroup({ title, ...inputProps }) {
	return (
		<InputGroup flexDirection="column">
			<AppText value={title} />
			<Input
				h="50px"
				_placeholder={{
					color: 'dark.primaryBg',
					opacity: 0.5
				}}
				color="dark.primaryBg"
				errorBorderColor="red.400"
				borderRadius="8px"
				fontSize={textFontSize}
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				{...inputProps}
			/>
		</InputGroup>
	)
}

export const PasswordInput = ({
	password,
	setPassword,
	isInvalid = false,
	setisInvalid = () => null,
	placeholder = '',
	title,
	...rest
}) => {
	const [show, setShow] = useState(false)
	const onChangePassword = (e) => {
		setisInvalid(false)
		setPassword(e.target.value)
	}

	return (
		<InputGroup flexDirection="column">
			<AppText value={title} />
			<Input
				type={show ? 'text' : 'password'}
				placeholder={placeholder}
				_placeholder={{
					color: 'dark.primaryBg',
					opacity: 0.5
				}}
				value={password}
				onChange={onChangePassword}
				isInvalid={isInvalid}
				errorBorderColor="red.400"
				borderRadius="8px"
				h="50px"
				fontSize={textFontSize}
				color="dark.primaryBg"
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				{...rest}
			/>

			<Button
				onClick={() => setShow(!show)}
				_hover={{ bg: 'dark.secondaryBg' }}
				bg="dark.secondaryBg"
				right="5px"
				bottom="5px"
				borderRadius="10px"
				position="absolute"
				w="60px"
				zIndex="99"
			>
				{show ? (
					<Text fontSize={smallFontSize}>Hide</Text>
				) : (
					<Text fontSize={smallFontSize}>Show</Text>
				)}
			</Button>
		</InputGroup>
	)
}

export function UserNumberInputField({
	title,
	id,
	stepper,
	value: newValue = '',
	...props
}) {
	const isTime = props.type === 'hours' || props.type === 'days'

	const format = (val) => {
		return value === '' ? '' : `${isTime ? '' : '£ '}${val}`
	}

	const [value, setValue] = React.useState('')

	const onChange = (value) => {
		setValue(value)
	}

	const onBlur = (e) => {
		const number = e.target.value
		if (number === '') {
			setValue('')
		} else {
			onChange(parseFloat(number) % 0.25 === 0 ? number : parseInt(number))
		}
	}

	useEffect(() => {
		setValue(newValue)
	}, [newValue])

	return (
		<HStack justifyContent="space-between" width="100%">
			{title && <AppText value={title} />}
			<NumberInput
				value={format(value)}
				onChange={(value) => onChange(value)}
				bg={useColorModeValue('light.selectedBgColor', 'dark.selectedBgColor')}
				borderRadius="10px"
				min={0}
				max={50}
				w="60%"
				{...props}
			>
				<NumberInputField
					h={['35px', '50px']}
					color={useColorModeValue('black', 'dark.primaryBg')}
					_placeholder={{ fontSize: textFontSize, color: 'gray' }}
					placeholder={isTime ? '0.00' : '£ 0.00'}
					fontSize={textFontSize}
					id={id}
					onBlur={onBlur}
					paddingLeft="10px"
				/>
				<NumberStepper />
			</NumberInput>
		</HStack>
	)
}

export function NumberStepper() {
	return (
		<NumberInputStepper
			flexDirection={'row'}
			width={['60px', '85px']}
			padding="4px"
			gap={'3px'}
		>
			<NumberDecrementStepper
				bg="pink.200"
				borderRadius="5px"
				_active={{
					bg: 'red.900'
				}}
				children="-"
				color="black"
				fontWeight={'bold'}
			/>
			<NumberIncrementStepper
				bg="green.200"
				borderRadius="5px"
				_active={{
					bg: 'green.800'
				}}
				children="+"
				color="black"
				fontWeight={'bold'}
			/>
		</NumberInputStepper>
	)
}
