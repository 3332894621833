import { createContext, useEffect, useState } from 'react'

import { getAllStaff } from '../common/methods'

export const StaffDataContext = createContext()

export function StaffDataProvider({ children }) {
	const [staff, setStaff] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const accessToken = localStorage.getItem('user-token')
	useEffect(() => {
		getAllStaff({ token: accessToken }).then((res) => {
			setIsLoading(false)
			setStaff(res)
		})
	}, [showEditModal, showDeleteModal])

	return (
		<StaffDataContext.Provider
			value={{
				showDeleteModal,
				showEditModal,
				setShowDeleteModal,
				setShowEditModal,
				staff,
				isLoading
			}}
		>
			{children}
		</StaffDataContext.Provider>
	)
}
