import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import { postResetPassword } from '../common/methods'
import { isMobile } from '../common/utils'
import LoginContainer from '../components/LoginContainer'
import {
	LoginInputGroup,
	SubmitButtonGroup,
	ErrorMessage
} from '../components/InputFields'

function ForgotPassword() {
	const [email, setEmail] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isEmailInvalid, setIsEmailInvalid] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const navigate = useNavigate()
	const toast = useToast()
	document.title = 'Forgot Password'

	const onSubmit = () => {
		setIsSubmitting(true)
		setIsEmailInvalid(false)
		const data = { email }
		postResetPassword({ data })
			.then(() => {
				toast({
					position: 'top',
					title: 'Please check your email!',
					duration: 3000,
					description:
						'If there is an account linked with this email, a reset password link has been sent.',
					status: 'info',
					containerStyle: {
						width: isMobile ? '300px' : '500px',
						marginTop: isMobile ? '20px' : '50px'
					},
					isClosable: true
				})
				navigate('/login')
				setIsSubmitting(false)
			})
			.catch((res) => {
				setErrorMessage(res.response.data.message)
				setIsSubmitting(false)
				setIsEmailInvalid(true)
			})
	}

	const onEmailChange = (e) => {
		setEmail(e.target.value)
	}

	return (
		<LoginContainer header="Forgot Password">
			{isEmailInvalid && <ErrorMessage message={errorMessage} />}
			<LoginInputGroup
				value={email}
				onChange={onEmailChange}
				isInvalid={isEmailInvalid}
				placeholder="Email"
			/>
			<SubmitButtonGroup
				onSubmit={onSubmit}
				isSubmitting={isSubmitting}
				isErrored={isEmailInvalid}
				errorMessage={errorMessage}
			/>
		</LoginContainer>
	)
}

export default ForgotPassword
