import {
	AppButton,
	AppText,
	ErrorMessage,
	UserNumberInputField,
	containerFontSize,
	smallFontSize,
	textFontSize
} from '../components/InputFields'
import { HStack, Spinner, Text, useColorMode } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { postConfirmPayrollData, postPayrollData } from '../common/methods'

import Container from '../components/Container'
import PageContainer from '../components/PageContainer'
import PaymentModal from './Modals/PaymentModal'
import { StaffDataContext } from '../providers/StaffProvider'
import StaffTable from '../components/Table'
import { ellipsesTextProps } from '../common/utils'
import { useContext } from 'react'

const Payroll = () => {
	const { colorMode } = useColorMode()
	const isDark = colorMode === 'dark'
	const accessToken = localStorage.getItem('user-token')
	document.title = 'Payroll'

	const [showPaymentModal, setShowPaymentModal] = useState(false)
	const { staff, isLoading } = useContext(StaffDataContext)
	const [amountPayable, setAmountPayable] = useState(null)
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [payrollId, setPayrollId] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSubmittingPayroll, setIsSubmittingPayroll] = useState(false)
	const isThereStaff = staff.length === 0

	const onCalculate = () => {
		setShowErrorMessage(false)
		setIsSubmitting(true)
		const staffPayrollData = []
		staff.forEach(({ id, holiday_pay_balance }) => {
			let hours = document.getElementById(`hours-${id}`).value
			hours = hours ? hours : 0
			let sickDays = document.getElementById(`days-sick-${id}`).value
			sickDays = sickDays ? sickDays : 0
			let holidayPay = document
				.getElementById(`holiday-pay-${id}`)
				.value.substring(2)
			holidayPay = holidayPay ? holidayPay : 0
			staffPayrollData.push({
				staff_id: id,
				hours,
				ssp_days: sickDays,
				holiday_pay: holidayPay
			})
		})

		const data = {
			week_ending_date: new Date(),
			staff: staffPayrollData
		}

		postPayrollData({ data, token: accessToken })
			.then(({ total_inc_vat, id }) => {
				setPayrollId(id)
				setAmountPayable(total_inc_vat)
			})
			.catch((res) => {
				setShowErrorMessage(true)
				setErrorMessage(res.response.data.message)
			})
			.finally(() => {
				setIsSubmitting(false)
			})
	}

	const onPay = () => {
		setIsSubmittingPayroll(true)
		postConfirmPayrollData({ id: payrollId, token: accessToken })
			.then(() => setShowPaymentModal(true))
			.finally(() => {
				setIsSubmittingPayroll(false)
			})
	}

	const weeklyTableRowData = staff.map(
		({ first_name, last_name, id, hourly_rate }) => [
			<Text
				color={isDark ? 'white' : 'black'}
				fontWeight="bold"
				textAlign={'center'}
				fontSize={textFontSize}
				maxWidth={['100px', '130px', '280px']}
				overflow="hidden"
				whiteSpace="nowrap"
				textOverflow="ellipsis"
			>
				{first_name + ' ' + last_name}
			</Text>,
			<Text
				color={isDark ? 'white' : 'black'}
				fontWeight="bold"
				textAlign={'center'}
				fontSize={textFontSize}
			>
				£ {parseFloat(hourly_rate).toFixed(2)}
			</Text>,
			<UserNumberInputField
				type="hours"
				id={`hours-${id}`}
				disabled={amountPayable}
				step={0.25}
				w={['120px', '175px']}
				mx="auto"
				stepper={true}
				min={0}
				max={70}
			/>
		]
	)

	const sickPayTableRowData = staff.map(({ first_name, last_name, id }) => [
		<Text
			color={isDark ? 'white' : 'black'}
			fontWeight="bold"
			textAlign={'center'}
			fontSize={textFontSize}
			maxWidth={['120px', '130px', '300px']}
			overflow="hidden"
			whiteSpace="nowrap"
			textOverflow="ellipsis"
		>
			{first_name + ' ' + last_name}
		</Text>,
		null,
		<UserNumberInputField
			type="days"
			id={`days-sick-${id}`}
			disabled={amountPayable}
			step={1}
			w={['120px', '175px']}
			mx="auto"
			min={0}
			max={7}
			stepper={true}
		/>
	])

	const holidayPayTableRowData = staff.map(
		({ first_name, last_name, id, holiday_pay_brought_forward }) => {
			return [
				<Text
					color={isDark ? 'white' : 'black'}
					fontWeight="bold"
					textAlign={'center'}
					fontSize={textFontSize}
					maxWidth={['105px', '130px', '280px']}
					{...ellipsesTextProps}
				>
					{first_name + ' ' + last_name}
				</Text>,
				<Text
					color={isDark ? 'white' : 'black'}
					fontWeight="bold"
					textAlign={'center'}
					fontSize={textFontSize}
					maxWidth={['100px', '170px', '250px']}
					{...ellipsesTextProps}
				>
					£ {parseFloat(holiday_pay_brought_forward).toFixed(2)}
				</Text>,
				<UserNumberInputField
					id={`holiday-pay-${id}`}
					disabled={amountPayable}
					step={0.25}
					w={['120px', '175px']}
					mx="auto"
					min={0}
					max={parseFloat(holiday_pay_brought_forward)}
					stepper={true}
					px="0"
				/>
			]
		}
	)

	const resetPayrollScreen = () => {
		setShowPaymentModal(false)
		setAmountPayable(null)
		setPayrollId(null)
		staff.forEach(({ id }) => {
			document.getElementById(`hours-${id}`).value = ''
			document.getElementById(`days-sick-${id}`).value = ''
		})
	}

	return (
		<PageContainer header="Payroll">
			<Container header="Weekly Payroll Form" disabled={amountPayable}>
				{isLoading ? <Spinner /> : isThereStaff ? (
					<AppText value="No staff work in this branch" />
				) : (
					<StaffTable
						headers={['Name', 'Rate', 'Hours']}
						rows={weeklyTableRowData}
					/>
				)}
			</Container>
			<Container header="Sick Pay Form" disabled={amountPayable}>
				{isLoading ? <Spinner /> : isThereStaff ? (
					<AppText value="No staff work in this branch" />
				) : (
					<StaffTable
						headers={['Name', null, 'Days']}
						rows={sickPayTableRowData}
					/>
				)}
			</Container>
			<Container header="Holiday Pay Form" disabled={amountPayable}>
				{isLoading ? <Spinner /> : isThereStaff ? (
					<AppText value="No staff work in this branch" />
				) : (
					<StaffTable
						headers={['Name', 'Balance', 'Pay']}
						rows={holidayPayTableRowData}
					/>
				)}
			</Container>
			<Container>
				{amountPayable ? (
					<>
						<Text fontSize={textFontSize} textAlign="center">
							Amount Due:
							<Text
								fontSize={containerFontSize}
								alignSelf={'end'}
								fontWeight="bold"
								display="inline-block"
								pl="5px"
							>
								£ {amountPayable}
							</Text>
							<Text fontSize={smallFontSize} display="inline-block" pl="5px">
								(inc. VAT)
							</Text>
						</Text>

						<Text fontSize={smallFontSize} textAlign="center" my="1rem">
							Payroll can only be submitted once per week, up until noon
							Wednesday, please ensure you have entered the correct hours.
							<Text>Thank you.</Text>
						</Text>

						<HStack justifyContent={'center'} spacing={7} mt={'15px'}>
							<AppButton
								onClick={onPay}
								value="Pay"
								isLoading={isSubmittingPayroll}
							/>
							<AppButton onClick={resetPayrollScreen} value="Reset" />
						</HStack>
					</>
				) : (
					<>
						<AppButton
							onClick={onCalculate}
							disabled={isThereStaff}
							isErrored={showErrorMessage}
							errorMessage={errorMessage}
							value="Calculate"
							isLoading={isSubmitting}
						/>
						{showErrorMessage && <ErrorMessage message={errorMessage} />}
					</>
				)}
			</Container>
			{showPaymentModal && (
				<PaymentModal
					show={showPaymentModal}
					onClose={resetPayrollScreen}
					amount={`£${amountPayable}`}
				/>
			)}
		</PageContainer>
	)
}

export default Payroll

export const ErrorBoundary = () => {
	return <h1>Error</h1>
}
