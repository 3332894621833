import React from 'react'
import FadeIn from 'react-fade-in'
import { Text, Box, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { containerFontSize } from './InputFields'

const LoginContainer = ({ children, header }) => {
	const isNavigable = [
		'Signup',
		'Forgot Password',
		'Change Password',
		'Register'
	].includes(header)

	const navigateHome = () => {
		localStorage.clear()
		navigate('/login')
	}

	const navigate = useNavigate()
	return (
		<FadeIn>
			<Flex
				gap={4}
				flexDirection="column"
				p="1rem"
				w={[300, 430]}
				alignItems="center"
				bg={'dark.secondaryBg'}
				borderRadius="5px"
				position="absolute"
				mr="auto"
				ml="auto"
				left={0}
				right={0}
				top={'100px'}
			>
				{isNavigable && (
					<Box
						type="button"
						position={'absolute'}
						color="#EEEEEE"
						onClick={navigateHome}
						left="20px"
						top={['20px', '23px']}
					>
						<ArrowBackIcon />
					</Box>
				)}
				<Text
					fontSize={containerFontSize}
					fontWeight="bold"
					color="dark.selectedBgColor"
				>
					{header}
				</Text>
				{children}
			</Flex>
		</FadeIn>
	)
}

export default LoginContainer
