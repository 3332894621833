import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr
} from '@chakra-ui/react'

import React from 'react'

const StaffTable = ({ headers = [], rows = [] }) => {
	return (
		<TableContainer margin="0 auto">
			<Table size="sm">
				<Thead>
					{headers.map((header) => (
						<Th
							textAlign="center"
							py={2}
							px={2}
							minWidth={['4rem', '4rem', '8rem']}
						>
							{header}
						</Th>
					))}
				</Thead>
				<Tbody>
					{rows.map((row) => (
						<Tr>
							{row.map((data) => (
								<Td py={2} px={2} lineHeight="30px">
									{data}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

export default StaffTable
