import axios from 'axios'

const generateErrorMessage = (response) => {
	return JSON.stringify(response)
	//return `A ${JSON.stringify(response.status} error occurred in ${method.toUpperCase()} request to ${response.config.url}.`;
}

const getHeaders = ({ token }) => {
	const auth = token && { Authorization: `Bearer ${token}` }

	return {
		'Access-Control-Allow-Origin': '*',
		...auth
	}
}

const postApiResponse = async (endpoint, data, token = '') => {
	const config = {
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}${endpoint}`,
		headers: getHeaders({ token }),
		data
	}

	const response = await axios.request(config)

	if (response.status >= 200 && response.status < 300) {
		return response.data
	}

	const error = new Error(generateErrorMessage(response))
	throw error
}

const getApiResponse = async (endpoint, data, token = null) => {
	const config = {
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}${endpoint}`,
		headers: getHeaders({ token }),
		data
	}

	const response = await axios.request(config)

	if (response.status >= 200 && response.status < 300) {
		return response.data
	}

	const error = new Error(generateErrorMessage(response))
	throw error
}

const putApiResponse = async (endpoint, data, token = null) => {
	const config = {
		method: 'PUT',
		headers: getHeaders({ token }),
		url: `${process.env.REACT_APP_API_URL}${endpoint}`,
		data
	}

	const response = await axios(config)

	if (response.status >= 200 && response.status < 300) {
		return response.data || 'Success'
	}

	const error = new Error(generateErrorMessage(response))
	throw error
}

const deleteApiResponse = async (endpoint, data, token = null) => {
	const config = {
		method: 'DELETE',
		url: `${process.env.REACT_APP_API_URL}${endpoint}`,
		headers: getHeaders({ token }),
		data
	}

	const response = await axios(config)

	if (response.status >= 200 && response.status < 300) {
		return response.data || 'Success'
	}

	const error = new Error(generateErrorMessage(response))
	throw error
}

export const postResetPassword = ({ data }) => {
	return postApiResponse('reset-password', data)
}

export const putResetPassword = ({ data }) => {
	return putApiResponse('reset-password', data)
}

export const putChangePassword = ({ data, token }) => {
	return putApiResponse('change-password', data, token)
}

export const postSignUp = ({ data }) => {
	return postApiResponse('signup', data)
}

export const postSignIn = ({ data }) => {
	return postApiResponse('signin', data)
}

export const getAccountInfo = ({ token = null }) => {
	return getApiResponse('account', null, token)
}
export const putAccountInfo = ({ data, token = null }) => {
	return putApiResponse('account', data, token)
}

export const getAllStaff = ({ token }) => {
	return getApiResponse('staff', null, token)
}

export const getStaffMember = ({ id, token }) => {
	const url = `/api/v1/staff/${id}`
	return getApiResponse(url, null, token)
}

export const postStaffMember = ({ data, token }) => {
	return postApiResponse('staff', data, token)
}

export const putStaffMember = ({ id, data, token }) => {
	const url = `staff/${id}`
	return putApiResponse(url, data, token)
}

export const deleteStaffMember = ({ id, token }) => {
	const url = `staff/${id}`
	return deleteApiResponse(url, null, token)
}

export const postPayrollData = ({ data, token }) => {
	return postApiResponse('payroll', data, token)
}

export const postConfirmPayrollData = ({ id, token }) => {
	const url = `payroll/${id}/confirm`
	return postApiResponse(url, null, token)
}

export const getNWeeksPayrollHistory = ({ weeks, token }) => {
	const url = `payroll?weeks=${weeks}`
	return getApiResponse(url, null, token)
}

export const getMetricsTemplate = ({ token }) => {
	return getApiResponse('metrics', null, token)
}

export const postMetrics = ({ data, token }) => {
	return postApiResponse('metrics/values', data, token)
}

export const getNWeeksMetricsHistory = ({ weeks, token }) => {
	const url = `metrics/values?weeks=${weeks}`
	return getApiResponse(url, null, token)
}

export const getRegisteredInfo = ({ id }) => {
	const url = `staff/register/${id}`
	return getApiResponse(url, null)
}

export const postRegisteredInfo = ({ data, token }) => {
	const url = 'staff/register'
	return postApiResponse(url, data, token)
}

///////////

export const postPaymentIntent = ({ id, token }) => {
	const url = `payroll/${id}/create-payment-intent`
	return postApiResponse(url, null, token)
}

export const getTermsConditions = () => {
	return getApiResponse('/api/v1/terms-and-conditions')
}

export const getUsefulLinks = () => {
	return getApiResponse('/api/v1/useful-links')
}
