import React from 'react'
import { useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { Text, Button, Center, useColorMode } from '@chakra-ui/react'
import { isMobile } from '../common/utils'

const Logout = () => {
	const navigate = useNavigate()
	const { colorMode, toggleColorMode } = useColorMode()
	const isLight = colorMode === 'light'

	const onLogout = () => {
		navigate('/login')
		localStorage.removeItem('user-token')
		isLight && toggleColorMode()
	}

	return (
		<Center>
			<Button
				type="button"
				onClick={onLogout}
				gap="10px"
				size={isMobile ? 'sm' : 'md'}
				mx="auto"
			>
				<Text size={isMobile ? 'sm' : 'md'}>Logout</Text>
				{!isMobile && <LogoutIcon fontSize="small" />}
			</Button>
		</Center>
	)
}

export default Logout
