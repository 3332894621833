import { Box, useColorModeValue } from '@chakra-ui/react'
import {
	Navigate,
	Outlet,
	Route,
	BrowserRouter as Router,
	Routes
} from 'react-router-dom'

import { AccountDataProvider } from './providers/AccountProvider.js'
import ChangePassword from './pages/ChangePassword'
import Error404 from './pages/error404'
import ErrorBoundaryHandler from './components/ErrorBoundary'
import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import Metrics from './pages/Metrics.jsx'
import { MetricsDataProvider } from './providers/MetricsProvider.js'
import Navbar from './components/Navbar'
import Payroll from './pages/Payroll'
import Profile from './pages/Profile'
import ProtectedRoute from './ProtectedRoute'
import React from 'react'
import Register from './pages/Register'
import RegistrationForm from './pages/RegistrationForm'
import Signup from './pages/Signup'
import { StaffDataProvider } from './providers/StaffProvider.js'
import ThankYou from './pages/ThankYou'

function App() {
	const token = localStorage.getItem('user-token')

	const LayoutsWithNavbar = () => (
		<ProtectedRoute>
			<MetricsDataProvider>
				<AccountDataProvider>
					<StaffDataProvider>
						<Navbar />
						<Outlet />
					</StaffDataProvider>
				</AccountDataProvider>
			</MetricsDataProvider>
		</ProtectedRoute>
	)

	return (
		<ErrorBoundaryHandler>
			<Box
				bg={useColorModeValue('light.primaryBg', 'dark.primaryBg')}
				width="100vw"
				min-height="100vh"
			>
				<Router>
					<Routes>
						<Route element={<LayoutsWithNavbar />}>
							<Route path="/home" element={<Metrics />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/staff" element={<RegistrationForm />} />
							<Route path="/payroll" element={<Payroll />} />
						</Route>
						<Route path="/reset-password" element={<ChangePassword />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/staff/register" element={<Register />} />
						<Route path="/staff/register/thanks" element={<ThankYou />} />
						<Route path="*" element={<Error404 />} />
						<Route
							path="/"
							element={
								<Navigate to={token ? '/home' : '/login'} replace={true} />
							}
						/>
					</Routes>
				</Router>
			</Box>
		</ErrorBoundaryHandler>
	)
}

export default App
