export const personalInfo = {
	full_name: 'Full Name (No short names)',
	email: 'Email',
	company_name: 'Company Name',
	address: 'Address',
	postcode: 'Postcode',
	telephone: 'Contact No.',
	date_of_birth: 'Date Of Birth'
}

export const bankAccount = {
	bank_account_name: 'Bank Account Name',
	bank_sort_code: 'Bank Account Sort Code',
	bank_account_number: 'Bank Account Number'
}

export const hmrcCompliant = {
	gender: 'Gender',
	studentLoan: 'Do you have a student Loan?',
	nationality: 'Nationality',
	national_insurance_number: 'National Insurance Number'
}
