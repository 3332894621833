import { Box, Flex, Text } from '@chakra-ui/react'

import React from 'react'
import { headerFontSize } from './InputFields'

const PageContainer = ({ header, children }) => {
	return (
		<Box
			w={['95%', '29rem', '40rem']}
			mx="auto"
			pt={['3.5rem', '85px']}
			minHeight={'100vh'}
			px="5px"
		>
			<Text fontSize={headerFontSize} fontWeight="bold" pl="5px">
				{header}
			</Text>
			<Flex gap={2} flexDirection="column">
				{children}
			</Flex>
		</Box>
	)
}

export default PageContainer
