import {
	AppButton,
	ErrorMessage,
	InputField,
	containerFontSize
} from '../../components/InputFields'
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'

import { AccountDataContext } from '../../providers/AccountProvider'
import { putAccountInfo } from '../../common/methods'

export function EditProfileModal({ show, onClose }) {
	const accessToken = localStorage.getItem('user-token')
	const [isSubmitting, setIsSubmitting] = useState('')
	const [isInputInvalid, setIsInputInvalid] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const { accountInfo, setShowEditProfileModal } =
		useContext(AccountDataContext)

	const [formState, setFormState] = useState({
		name: accountInfo.name,
		email: accountInfo.email,
		business_name: accountInfo.business_name
	})

	const handleChange = (e) => {
		setFormState({
			...formState,
			[e.target.id]: e.target.value || null
		})
	}

	const submitData = () => {
		setIsSubmitting(true)
		setIsInputInvalid(false)
		const data = {
			name: formState.name,
			email: formState.email.toLowerCase(),
			business_name: formState.business_name
		}
		putAccountInfo({ data, token: accessToken })
			.then(() => {
				setIsSubmitting(false)
				onClose()
			})
			.catch((res) => {
				setIsInputInvalid(true)
				setErrorMessage(res.response.data.message)
				setIsSubmitting(false)
			})
	}

	return (
		<Modal
			isOpen={show}
			onClose={onClose}
			size={window.innerWidth < 500 ? 'xs' : 'lg'}
		>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent
				bg={useColorModeValue('light.secondaryBg', 'dark.secondaryBg')}
				color={useColorModeValue('black', 'dark.textColor')}
			>
				<ModalHeader px="20px" py="15px">
					<Text fontSize={containerFontSize}>Edit Profile</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody py={0}>
					<InputField
						placeholder="Name"
						id="name"
						title="Name"
						value={formState.name}
						onChange={handleChange}
					/>
					<InputField
						placeholder="Email"
						id="email"
						title="Email"
						value={formState.email}
						onChange={handleChange}
					/>
					<InputField
						placeholder="Business"
						id="business_name"
						title="Business"
						value={formState.business_name}
						onChange={handleChange}
					/>
				</ModalBody>
				<ModalFooter justifyContent="center" flexDirection="column" gap="10px">
					<AppButton
						onClick={submitData}
						value="Submit"
						mt="15px"
						isErrored={isInputInvalid}
						isLoading={isSubmitting}
						errorMessage={errorMessage}
					/>
					{isInputInvalid && <ErrorMessage message={errorMessage} />}
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default EditProfileModal
